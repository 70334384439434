* {
  padding: 0;
  margin: 0;
}

:root {
  --card-bg: rgba(255, 255, 255, 0.876);
  --button: rgba(233, 233, 233, 0.742);
  --button-hover: rgb(0, 0, 0);

  --main-text-color: #000;
  --btn-text-color: rgb(0, 0, 0);
  --btn-text-color-hover: #fff;

}

body {
  font-family: "Popins";
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
}

.card-background {
  background-color: var(--card-bg);
  color: var(--main-text-color);
  padding: 20px;
  border-radius: 20px;
  max-width: 600px;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); */
  box-shadow: 4px 10px 15px rgba(0, 0, 0, 0.2);
}

.card-background .card_body {
  /* display: flex;
  justify-content: space-between;
  align-items: center; */
  padding: 6px 12px;
  border-radius: 10px;

}

/* .card .card_body .card_body_left {
  display: flex;
  justify-content: space-between;
  align-items: center;
} */

.card-background h1 {
  font-size: 20px;
}

.card-background h3 {
  font-size: 22px;
}

.card-background p {
  font-size: 16px;
}

/* .mt-16 {
  margin-top: 16px;
} */

.text-center {
  text-align: center;
}

.profile .avatar {
  background-color: var(--button);
  margin-top: 10px;
  padding: 4px;
  border-radius: 7%;
  width: 250px;
  /* border: 2px solid var(--button); */
  box-shadow: 4px 5px 10px rgba(0, 0, 0, 0.2);
}

.card-background .link {
  margin-top: 10px;
}

.card-background .btn_action {
  background-color: var(--button);
  color: var(--btn-text-color);

  margin-left: 10px;
  padding: 10px 20px;
  width: 100%;
  border-radius: 10px;

  text-align: center;
  text-decoration: none;
  font-size: 16px;
  display: block;

  box-shadow: 4px 3px 3px rgba(0, 0, 0, 0.2);

  transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.card-background .btn_action:hover {
  background-color: var(--button-hover);
  color: var(--btn-text-color-hover);
  font-size: 18px;
  padding: 15px;
}

/* .particles-js {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0);
  background-image: url("");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
} */

@media only screen and (max-width: 1000px) {

  .card-background .link {
    margin-top: -10px;
  }

  .card-background .btn_action {
    margin-left: 0px;
    font-size: 18px;
  }

  .card-background .btn_action:hover {
    font-size: 20px;
  }

  .profile .avatar {
    margin-top: -15px;
    padding: 2px;
    width: 120px;
    border-radius: 100%;
    border: 3px solid rgb(255, 255, 255);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }
}